#profilePage {
    min-height: 84vh;
    margin-top: 2%;
}

#profilePhoto {
    height: 250px;
    width: 250px;
    border: 5px var(--colour1) solid;
    margin-top: 5%;
    margin: 0 auto;
    object-fit: cover;
}

#profileInfoSection h2 {
    color: var(--colour5);
    font-family: Roboto;
    font-size: 2vh;
    padding: 1vh;
}

#skillsHeading {
    font-size: 3vh;
    font-family: Arial;
    position: relative;
    overflow: hidden;
    color: var(--colour4);
}

#skillsHeading:after {
    display: inline-block;
    content: "";
    height: 2px;
    background: var(--colour4);
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -2px;
    margin-left: 10px;
}

.skillListItem {
    color: var(--colour5);
    font-family: Roboto;
    font-size: 3vh;
}

#profileInfoWrapper {
    width: 100%;
    min-height: 70vh;
    background: var(--colour6);
    position: relative;

}

#name {
    color: var(--colour2);
    display: inline-block;
    font-size: 5vh;
    margin: 3% 3% 0 3%;
}

#location {
    display: inline-block;
    color: var(--textColour);
    font-size: 2vh;
    font-weight: 100;
}

#profession {
    margin-left: 3%;
    color: var(--colour5);
    font-size: 2.5vh;
}

#profileSelectBox {
    width: 80%;
    margin: 0 auto;
}

#about,
#commendations {
    display: inline-block;
    color: black;
    font-size: 2.5vh;
    cursor: pointer;
}


#commendations {
    margin-left: 3%;
}

#aboutCommendationsHR {
    border-color: black;
    margin: 0 auto;
}

#bioWrapper {
    padding: 10px;
}


/* Customize the appearance of the webkit-based scrollbars */
#bioWrapper::-webkit-scrollbar {
    width: 0.4em;
}

#bioWrapper::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px var(--colour6);
    -webkit-box-shadow: inset 0 0 6px var(--colour6);
}

#bioWrapper::-webkit-scrollbar-thumb {
    background-color: var(--colour6);
    outline: 1px solid var(--colour6);
}


#bio {
    color: var(--textColour);
}

#description {
    color: var(--textColour);
    max-width: 100%;
    word-wrap: break-word;
    min-height: 25vh;
}

#reportUser {
    text-align: end;
    margin: 1%;
}
#reportUser:hover {
    color: var(--declineColour);
    cursor: pointer;
}
#commendationsWrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 2%;
}
#commendButton {
    background: var(--colour2);
    margin: 0 auto;
    border: none;
}
#commendationPostWrapper {
    height: 45vh;
    padding: 10px;
    overflow: auto;
    background-color: var(--colour1);
    width: 100%;
    text-align: center;
    color: var(--textColour);
    font-size: large;
}
#imageUploadWrapper {
    display: flex;
    justify-content: center;
}
#commendation {
    width: 100%;
    display: flex;
    justify-content: start;
    margin: 1% 0 1% 0; 
}
#commendationImage {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    max-height: 100px;
    min-height: 100px;
    border-radius: 50%;
    object-fit: cover;
    margin: 2%;
}
#commendationUsername {
    text-align: left;
    font-size: larger;
}
#commendationMessage {
    text-align: left;
}
#commendationAgreeButton {
    color: var(--colour3);
    position: relative;
    float: right;
    cursor: pointer;
}
#commendationAgreeButton:hover, #commendationDeleteButton:hover {
    color: var(--textColour);
}
#commendationAgrees {
    color: var(--colour1);
    position: relative;
    float: left;
}
#commendationDeleteButton {
    cursor: pointer;
    color: red;
    position: relative;
    float: right;
    margin-left: 1%;
}
#socialsWrapper {
    width: 100%;
}
#socials {
    color: var(--textColour);
}
.social {
    width: 48%;
}
.socialDefault {
    width: 10%;
}
.socialIcon {
    width: 100px;
    height: 100px;
    color: #999;
}
#socialsButtons {
    width: 70%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
}
@media only screen and (max-width: 1210px) {
    #socialsButtons {
        width: 100%;
    }
    p, b,li, span {
        font-size: x-large;
    }
    .aboutAndCommendationsTabs {
        font-size: medium;
    }
    #commendation {
        word-wrap: break-word;
    }
    #profileSelectBox {
        width: 95%;
    }
}
@media only screen and (max-width: 770px) {
    
    #profilePhoto {
        display: block;
    }
}


