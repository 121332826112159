.filterDisplay {
    display: flex;
    background-color: var(--colour1);
    width: 100%;
    justify-content: space-evenly;
}

#bugReportWrapper {
    background-color: #FFBF00;
    height: 30px;
    font-size: small;
    width: 100vw;
    overflow: hidden;
    white-space: nowrap;
}

#bugReportText {
    color: black;
    font-size: larger;
    text-align: center;
    width: 100%;
}

#bugReportText span {
    color: var(--textColour);
    cursor: pointer;
}

#noCardsToDisplay {
    color: var(--textColour);
    text-align: center;
    opacity: 0.5;
}
#homescreenJobWrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content:space-evenly;

}
#filterBoxWrapper {
    display: inline;
}

#filterButton {
    display: none;
}
#chipWrapper {
    display: none;
}
#homeWrapper {
    min-height: 100vh;
}

@media only screen and (max-width: 1210px) {
    #bugReportWrapper {
        overflow: auto;
        white-space: normal;
        height: auto;
    }

    #filterBoxWrapper {
        display: none;
    }

    #filterButton {
        display: inline-block;
        height: 40px;
        background-color: var(--colour4);
        border-color: #212529;
        border-radius: 0;
        overflow: hidden;
        width: 16%;
        white-space: nowrap;
    }
    #chipWrapper {
        display: inline;
        width: 80%;
        margin: 0 auto;
    }
    #submitItHereLink {
        font-size: medium;
    }
}