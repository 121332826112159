#loginLogo {
    top: 10%;
}
#loginWrapper {
    min-height: 100vh;
}

@media only screen and (max-width: 1210px) {
    #loginLogo {
        top: 20%;
        display: block;
        position: relative;
        width: 100%;
    }   
}
@media only screen and (max-width: 770px) {
    #loginLogo {
        display: none;
    }   
}