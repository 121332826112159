#createJobForm {
    width: 70%;
    margin: 0 auto;
    padding: 5%;
    color: var(--textColour);
}
.createJobLabels {
    font-size: 4vh;
}

#addTagButton {
    color: var(--textColour);
    background-color: var(--colour5);
    border: none;
    border-radius: 0px;
    width: 33%;
    margin-top: 3%;
}
#dropdownItemButton {
    width: 100%;
}
.dropdownTextInput {
    width: 100%;
}
#imageUploadWrapper {
    display: flex;
    justify-content: center;
}
.technologyGroup {
    display: flex;
    flex-wrap: wrap; 
    justify-content: space-between; 
    width: 100%; 
    margin-bottom: 10px; 
}
#modalStyle {
    max-width: 70%; /* Set max-width to 80% */
    width: 70%;
}
@media only screen and (max-width: 1210px) {
    #createJobForm {
        width: 100%;
    }
    #modalStyle {
        max-width: 95vw;
        width: 100vw;
    }
}