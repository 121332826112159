.skillChip {
    margin: 1%;
}
#addSkillButton {
    color: var(--textColour);
    background-color: var(--colour5);
    border: none;
    border-radius: 0px;
    width: 33%;
    margin-top: 3%;
}
.editProfileText {
    color: var(--textColour);
}
.seperator {
    height: 20px;
}
#submitButton {
    background-color: var(--colour2);
    border: none;
    margin: 1%;
    float:  right;
}
#cancelButton {
    background-color: var(--colour3);
    border: none;
    margin: 1%;
    float:  right;    
}