#settingsWrapper {
    background-color: #464B566E;
    width: 80%;
    margin: 0 auto;
    margin-top: 5%;
    min-height:100vh;
    margin-bottom: 5%;
    padding: 2%;
}

#updateValuesWrapper {
    width: 70%;
    margin: 0 auto;
}

.settingsText {
    color: var(--textColour);
    font-size: large;
    font-weight: bold;
    width: 100%;
}

#subscriptionButtonWrapper {
    float: right;
    display: inline-block;
}

#passwordButtonWrapper {
    display: inline-block;
}

#changePasswordButton {
    border-radius: 20px;
    background: var(--colour4);
    width: 200px;
    margin: 2%;
    font-size: large;
    border: none;
}

#deleteAccountButton {
    border-radius: 20px;
    background: #D41818;
    width: 200px;
    margin: 2%;
    font-size: large;
    border: none;
}

#logoutHr {
    display: none;
}

#logoutButton {
    border-radius: 20px;
    background: #D41818;
    width: 200px;
    margin: 2%;
    font-size: large;
    border: none;
    display: none;
}

.editButton {
    text-align: end;
    font: italic;
    font-weight: 100;
    cursor: pointer;
    border: none;
}

.settingsValueText {
    color: var(--colour2)
}

.userData {
    font-weight: 100;
    font-size: large;
}

.passwordInputs {
    width: 80%;
    display: inline-block;
    margin: 2%;
}

.alert {
    position: absolute;
    left: 0;
    right: 0;
    top: 10%;
    width: 400px;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.settingsUpdateInput {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 35%;
}

#transactionHistoryTable {
    overflow: hidden;
    background-color: var(--colour1);
    margin: 0 auto;
}
#dangerZone {
    margin-top: 5%;
    background-color: var(--colour6);
    padding: 5px 0 5% 5px;
}

@media only screen and (max-width: 1210px) {
    #settingsWrapper #subscriptionButtonWrapper {
        text-align: center;
    }

    #updateValuesWrapper {
        width: 100%;
    }

    .settingsUpdateInput {
        width: 80%;
    }

    #logoutHr {
        display: block;
    }

    #logoutButton {
        display: inline-block;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #deleteAccountButton {
        display: inline-block;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    #settingsWrapper {
        width: 100%;
    }
    #passwordButtonWrapper {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}