#heading {
    color: var(--textColour);
    margin: 1%;
}
#deleteModalHeader {
    background-color: var(--colour1);
    color: var(--textColour)
}
#deleteModalBody {
    background-color: var(--colour1);
    color: var(--textColour)
}
.dashboardJob {
    width: 70%;
    margin: 0 auto;
    margin-top: 1%;
    color: var(--textColour);
    padding: 2%;
}

.dashboardJob h1 {
    font-size: x-large;
}

#dashboardJobWrapper {
    background-color: var(--colour6);
    width: 70%;
    margin: 0 auto;
    margin-top: 1%;
}
#dashboardWrapper {
    min-height: 90vh;
}

#submission {
    background-color: var(--textColour);
    padding: 1%;
}

#acceptBtn {
    background-color: var(--acceptColour);
}

#rejectBtn {
    background-color: var(--declineColour);
}

#rejectBtn,
#acceptBtn {
    border: none;
}

#createJobButton {
    display: inline-block;
    height: 40px;
    background-color: var(--colour2);
    border-color: #212529;
    border-radius: 0;
    margin-bottom: 3%;
}

#createJobWrapper {
    text-align: center;
    color: var(--textColour);
}

.deleteModalNote {
    background-color: var(--colour6);
    padding: 3%;
    border-radius: 5px;
}
.deleteModalNote p {
    font-size: small;
}

.acceptDisagreeButton {
    background-color: var(--declineColour);
    border: none;
    margin-left: 5%;
}

.acceptAgreeButton {
    background-color: var(--acceptColour);
    border: none;
}
.acceptDisagreeButton:hover {
    background-color: var(--declineColourHover);
}

.acceptAgreeButton:hover {
    background-color: var(--acceptColourHover);
}

.rejectDisagreeButton {
    background-color: var(--declineColour);
    border: none;
    margin-left: 5%;
}
.rejectDisagreeButton:hover {
    background-color: var(--declineColourHover);
}

.rejectAgreeButton {
    background-color: var(--acceptColour);
    border: none;
}
.rejectAgreeButton:hover {
    background-color: var(--acceptColourHover);
}

@media only screen and (max-width: 1210px) {
    #dashboardJobWrapper {
        width: 100%;
    }

    .dashboardJob {
        width: 100%;
    }

    .submissionLinkWrapper {
        width: 200px;
        word-wrap: break-word;
    }

}