#jobPageWrapper {
    min-height: 100vh;
}
#detailsWrapper {
    background-color: var(--colour6);
    margin-top: 10%;
    width: 100%;
    font-size: larger;
    padding: 3%;
    margin-bottom: 3%;
}
#detailsWrapper h2 {
    color: var(--colour5);
    font-size: 1.5vw;
}
#detailsWrapper li {
    color: var(--colour4)
}
.tagChip {
    margin: 2%;
}
#descriptionWrapper {
    margin-top: 3%;
    padding: 5%;
    font-size: large;
    color: var(--textColour);
}
#chipWrapper {
    width: 100%;
    margin: 0 auto;
}
#posterPicture {
    height: 200px;
    width: 200px;
    object-fit: cover;
    border-radius: 50%;
    cursor: pointer;
}
#imageWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
#posterDetailsWrapper {
    text-align: center;
}
#description {
    max-width: 100%;
    overflow-wrap: break-word;
    white-space: pre-wrap;
    background-color: var(--colour6);
    padding: 2%;
}
#posterWrapper {
    background: var(--colour6);
    margin: 0 auto;
    margin-bottom: 5%;
    padding: 2%;
    text-align: left;
}
#buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 10%;
}
#contactButton {
    background-color: var(--colour3);
    color: var(--textColour);
    font-size: large;
}
#contactButton:disabled {
    opacity: 0.3;
    background-color: grey;
}
#submitProjectButton {
    background-color: var(--colour2);
    color: var(--textColour);
    font-size: large;
}
#submitProjectButton:disabled {
    background-color: grey;
    opacity: 0.3;
}
#increaseBountyButton {
    background-color: var(--colour3);
    color: var(--textColour);
    font-size: large;
}
.skillChip {
    padding: 5px 0 0 0;
}
#imageCarousel {
    height: 400px; 
    width: 400px;
    margin: 0 auto;
    margin-top: 20px;
}
@media only screen and (max-width: 1210px) {
    #detailsWrapper h2 {
        font-size: larger;
    }
    #posterWrapper h2 {
        font-size: large;
    }
    #posterWrapper h3 {
        font-size: larger;
    }
    #posterWrapper p {
        font-size: larger; 
    }
    #posterWrapper {
        padding: 1%;
    }
    #posterPicture {
        height: 150px;
        width: 150px;
    }
    #submitProjectButton {
        width: 100%;
        margin-bottom: 10px;
    }
    #increaseBountyButton {
        width: 100%;
        margin-bottom: 10px;
    }
    #contactButton { 
        width: 100%;
        margin-bottom: 10px;
    }
    #buttons {
        display: block;
    }
    .skillChip {
        padding: 0 0 5px 0;
    }
    
}
@media only screen and (max-width: 573px) {
    #imageCarousel {
        height: 400px; 
        width: 94vw;
    }
}