#tsAndcsWrapper {
    min-height: 100vh;
}

#wrapper {
    width: 90%;
    padding: 2%;
    margin: 0 auto;
    margin-top: 3%;
    text-align: center;
    color: var(--textColour);
}

#textDisplay {
    background-color: var(--colour1);
    font-size: large;
    text-align: left;
    overflow: scroll;
    padding: 5%;
    overflow-x: hidden;
    /* Hide horizontal scrollbar */
    overflow-y: auto;
}
#checkBox {
    margin: 0 auto;
    display: flex;
    justify-content: center;
}
#continueButton {
    background-color: green;
    color: var(--textColour);
    margin-left: 40%;
}

#backButton {
    background-color: red;
}
@media only screen and (max-width: 1210px) {
    #wrapper {
        width: 100%;
    }
    #textDisplay {
        max-height: 70vh;
    }
}