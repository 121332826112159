#spinnerWrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50%;
    min-height: 100vh;
}   
#spinner {
    width: 90px;
    height: 90px;
    color: var(--textColour);
    font-size: large;
}