#forgotPasswordContainer {
    min-height: 100vh;
}

#wrapper {
    background-color: #464B566E;
    color: var(--textColour);
    width: 30%;
    margin: 0 auto;
    margin-top: 15%;
    padding: 50px;
}

#emailInput {
    width: 100%;
}

#alertBanner {
    width: 40%;
    margin: 0 auto;
    margin-top: 1%;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.heading {
    font-size: x-large;
}

@media only screen and (max-width: 1210px) {
    #wrapper {
        width: 50%;
        margin-top: 15%;
    }
}

@media only screen and (max-width: 770px) {
    #wrapper {
        width: 90%;
        margin-top: 15%;
    }
}