#sendButton {
    background-color: var(--colour2);
    border: none;
    margin: 1%;
    float: right;
}
#modalBody {
    background-color: var(--colour1);
    color: var(--textColour);
}
#modalHeader {
    background-color: var(--colour1);
    color: var(--textColour);
}
#iconWrapper {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
}
.icon {
    font-size: 42px;
    cursor: pointer;
    color: #a5a5a5;
}
.icon:hover {
    color: #d3d2d2;
}
#baseUrl {
    background-color:#e0dede;
}
.emailModal {
    background-color: var(--colour1);
    color: var(--textColour);
}