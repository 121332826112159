#signUpWrapper {
    min-height: 100vh;
}
#signUpFormContainer {
    width: 100%;
    justify-content: center;
    display: flex;
    top: 10%;
}
#signUpDetailsForm {
    padding: 2%;
    width: 100%;
    top: 5%;
    border-radius: 15px;
}
#signUpHeading {
    color: var(--withe, #FFF);
    font-size: 96px;
    font-style: italic;
    font-weight: 10;
    line-height: normal;
}
#signUpForm {
    padding: 2%;
    width: 100%;
    border-radius: 15px;
}
.signUpLabels {
    color: var(--colour2);
    font-size: 30px;
    font-weight: bold;
}

.seperator {
    height: 20px;
}
#signUpLogo {
    top: 15%;
}
#signUpButton {
    color: var(--textColour);
    background-color: var(--colour5);
    border: none;
    border-radius: 0px;
    float: right;
    width: 33%;
    margin-top: 3%;
    margin-bottom: 100px;
}
#addSkillButton {
    color: var(--textColour);
    background-color: var(--colour5);
    border: none;
    border-radius: 0px;
    width: 33%;
    margin-top: 3%;
}
#signUpBackButton {
    color: var(--textColour);
    border: none;
    border-radius: 0px;
    float: left;
    width: 33%;
    margin-top: 3%;
    background-color: var(--colour2);
}
#signUpProfilePic {
    width: 400px;
    height: 400px;
    border-radius: 50%;
    overflow: 'hidden';
    margin: 0 auto;
    margin-top: 40%;

}
#descriptionInput {
    background-color: #24292f;
    width: 100%;
    color: #999;
}
#descriptionInput:hover {
    border-color: white;
}
#socialIconsContainer {
    display: flex;
}
.socialIcon {
    cursor: pointer;
    color: #999;
    margin: 0 auto;
}
#instagramImage {
    width: 95px;
    height: 95px;
    transform: scaleX(-1);
}
.skillChip {
    margin: 1%;
}
#skipForNowButton {
    color: var(--textColour);
    font-size: larger;
    border: none;
    border-radius: 0px;
    float: left;
    width: 33%;
    margin-top: 3%;
    text-align: center;
}

@media only screen and (max-width: 1210px) {
    #signUpLogo {
        display: block;
        position: relative;
        width: 100%;
    }  
    #signUpForm {
        width: 100%;
        position: relative;
    }
    #signUpDetailsForm {
        width: 100%;
        position: relative;
    }
}
@media only screen and (max-width: 770px) {
    #signUpLogo {
        display: none;
    }
    #signUpProfilePic {
        display: none;
    }
    #signUpForm {
        justify-content: center;
        margin: 0 10% 0 10%;
        width: 75%;
    }
    #signUpDetailsForm {
        justify-content: center;
        position: relative;
        width: 100%;
    }
    #socialInputWrapper {
        width: 100%;
    }
}
