#releasePaymentText {
    color: var(--textColour);
}
#body {
    background-color: var(--colour1);
    color: var(--textColour);
    text-align: center;
}
#buttonsWrapper {
    display: flex;
    justify-content: space-evenly;
}
#cancelButton {
    background-color: red;
    color: var(--textColour);
    float: right;
    margin-left: 20px;
}
#releasePaymentButton {
    background-color: var(--colour4);
    color: var(--textColour);
    float: right;
    margin-left: 20px;
}