#requestChangesText {
    color: var(--textColour);
}
#body {
    background-color: var(--colour1);
}
.inputLabel {
    color: var(--textColour);
    margin-top: 10px;
}

#submitChangeRequestButton {
    background-color: var(--colour3); 
    color: var(--textColour); 
    margin-top: 15px;
    float: right;
}