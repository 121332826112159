#submitButton {
    background-color: var(--colour2);
    border: none;
    margin: 1%;
    float: right;
}
#cancelButton {
    background-color: var(--colour3);
    border: none;
    margin: 1%;    
    float: right;
}

#imageUploadWrapper {
    display: flex;
    justify-content: center;
}
.image {
    width: 150px;
    height: 150px;
}
@media only screen and (max-width: 1210px) {
    .image {
    width: 28vw;
    height: 28vw;
    }
}
