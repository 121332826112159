#searchInput {
    width: 80%;
    margin-left: 5%;
    height: 40px;
    color: var(--textColour);
    background-color: #464B56;
    border: none;
    opacity: 0.8;
}
#searchInput:focus {
    border: 3px solid #212529;
}
#searchInput::placeholder {
    color: #939393;
    padding: 0.5%;
    font-size: small;
}
#searchBarWrapper {
    margin: 10% 0 0 0;
    display: flex;
    justify-content: center;
}
#searchButton {
    background-color: #565c68;
    border-color: #212529;
    border: none;
}
#filterMenuItem {
    
}
#filterButton {
    height: 40px;
    background-color: #464B56;
    border-color: #212529;
    border-radius: 0;
    color: var(--textColour);
}
#sortCardsButton {
    display: inline-block;
    height: 40px;
    background-color: var(--colour3);
    border-color: #212529;
    border-radius: 0;
    white-space: nowrap;
    overflow: hidden;
}

#createJobButton {
    display: inline-block;
    height: 40px;
    background-color: var(--colour2);
    border-color: #212529;
    border-radius: 0;
    overflow: hidden;
    white-space: nowrap;
}
#filterButtons {
    width: 100%;
    margin-left: 5%;
    margin-bottom: 10%;
    display: flex;

}
#filterWrapper {
    background-color: var(--colour1);
    padding: 5%;
    display: flex;
    flex-direction: column; 
    width: 100%;
}
.filterBox {
    width: 20%;
}
.technologyGroup {
    display: flex;
    flex-wrap: wrap; 
    width: 100%; 
    margin-bottom: 10px; 
}
#searchIcon {
    display: none;
}
@media only screen and (max-width: 1210px) {
    #searchInput {
        width: 80%;
        margin-top: 10%;
    }
    #searchButton {
        margin-left: 5%;
    }
    #searchInput {
        width: 80%;
        margin-left: 0;
    }
    #searchIcon {
        display: inline-block;
        margin-top: 10%;
    }
    #searchButton {
        display: none;
    }
    #filterButtons {
        margin-left: 0;
    }
    
    .filterBox {
        width: 80%;
    }
    #searchBarWrapper {
        margin-top: 75px; 
        margin-bottom: -20px;
    }
}
