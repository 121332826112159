#menu {
    width: 100%;
    background-color: var(--menuColour);
}

#menuTitle {
    color: var(--colour2);
}

.custom-google-logout-button {
    color: #ffffff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
}

.dropDownMenuItem {
    color: black;
    display: none;
}

#searchWrapper {
    margin: 0 auto;
    display: block;
    width: 30%;
    position: relative;
}
#homeIcon {
    display: inline-block;
}
.searchItem {
    color: var(--textColour);
    display: block;

    background-color: var(--colour1);
    padding: 1%;
    border: 1px solid grey;
    z-index: 999;

}

.searchItem:hover {
    animation-name: hoverTransition;
    animation-duration: 1s;
    animation-duration: infinite;
    animation-fill-mode: forwards;
}

.dropdownContainer {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    cursor: pointer;
    z-index: 100;

}
#loginSignupButtons, #signupButton, #loginButton {
    color: var(--colour2);
}
#signupButton:hover {
    color: var(--textColour);
    cursor: pointer;
}
#loginButton:hover {
    color: var(--textColour);
    cursor: pointer;
}
#dropDownHomeMenuItem {
    display: none;
}
#loginSignupButtons {
    font-size: medium;
    margin-bottom: 0;
    margin-left: 10px;
}
@keyframes hoverTransition {
    0% {
        background-color: var(--colour1);
    }

    100% {
        background-color: var(--colour3);
    }
}
@media only screen and (max-width: 770px) {
    #searchWrapper {
        width: 100%;
    }
    #homeIcon {
        display: none;
    }
    #dropDownHomeMenuItem {
        display: inline-block;
    }
}