html,
body {
  background-color: var(--colour1);
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

hr {
  border: 1px solid var(--colour4);
  opacity: 1;
}

li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

#clearfix {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f1f1f1;
}

body {
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.custom-scrollbar {
  overflow-y: scroll;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

#footer-container {
  height: 10%;
  width: 100%;
}

#footer-logo {
  margin-left: 1%;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

@media only screen and (max-width: 1210px) {
  #menu {
    display: inline;
  }

  #footer-logo {
    display: none;
  }

  #footer-wrapper {
    width: 100%;

  }

  #footer {
    width: 100%;
    justify-content: center;
  }
}