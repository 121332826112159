#submissionPageWrapper {
    min-height: 100vh;
    margin-top: 15px;
}
#leftColumn {
    height: 80vh;
}

#displaySubmissionsWrapper {
    width: 100%;
    height: 9.5%;
    margin-bottom: 0.5%;
}

#displayFilesWrapper {
    background-color: var(--colour6);
    padding: 10px;
    display: flex;
    height: 90%;
    justify-content: center;
    align-items: center;
}

#uploadedFilesSelector {
    min-width: 100%;
}

#downloadButton {
    background-color: var(--colour2);
    color: var(--textColour);
    margin-left: 10px;
    font-size: 13px;
    width: 180px;
    display: inline;
}

#deleteButton {
    background-color: red;
    color: var(--textColour);
    margin-left: 10px;
    font-size: 13px;
    width: 180px;
    display: inline;
}

#fileWrapper {
    max-height: 90%;
    min-width: 100%;
    margin-top: 5px;
    overflow: auto;
}

#displayFileTileMessage {
    margin-top: 100%;
    text-align: center;
    color: var(--textColour);
}

/* ====================================== */
#rightColumn {
    height: 80vh;
}

#displayUploadWrapper {
    background-color: var(--colour6);
    padding: 10px;
    height: 20%;
}

#fileUploadComponentWrapper {
    width: 80%;
    margin: 0 auto;
}

#dropzone {
    border: 2px dashed #cccccc;
    border-radius: 4px;
    width: 100%;
    display: block;
    padding: 20px;
    text-align: center;
    cursor: pointer;
    color: var(--textColour);
}

#uploadButton {
    margin-top: 20px;
    padding: 10px 10px;
    background-color: var(--colour4);
    color: var(--textColour);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    margin-bottom: 10px;
    width: 150px;
}

#removePendingUploadsButton {
    margin-top: 20px;
    margin-right: 10px;
    padding: 10px 20px;
    background-color: red;
    color: var(--textColour);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: right;
    margin-bottom: 10px;
}

#notifyCustomerButton {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: var(--colour2);
    color: var(--textColour);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    float: left;
    margin-bottom: 10px;
}

#uploadTileMessage {
    text-align: center;
}

#uploadFilesWrapper {
    background-color: var(--colour6);
    height: 80%;
}

#pendingFiles {
    color: var(--textColour);
    width: 90%;
    max-height: 90%;
    min-height: 90%;
    overflow: auto;
    margin: 0 auto;
}

.fileInfo {
    padding: 4px 0 4px 0;
}

.uploadFileRemove {
    float: right;
    color: var(--declineColour);
    cursor: pointer;
}

.uploadFileRemove:hover {
    color: var(--declineColourHover);
}

#displayFilesHeading {
    height: 100%;
}
#selectedSubmissionWriteUp {
    color: var(--textColour);
    font-size: medium;
    margin: 5%;
    background-color: var(--colour6);
    padding: 3%;
}
@keyframes hoverAnimation {
    0% {
        background-color: none;
    }

    100% {
        background-color: var(--colour6);
    }
}

@media only screen and (max-width: 770px) {
    #rightColumn {
        height: 100vh;
    }

    #leftColumn {
        height: 50vh;
        margin-bottom: 30%;
    }

    #pendingFiles {
        max-height: auto;
        min-height: 0%;
        overflow: auto;
        margin: 0 auto;
    }

    #displayUploadWrapper {
        height: auto;
    }

    #fileUploadComponentWrapper {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
    }

    #removePendingUploadsButton {
        margin: 0 auto;
        width: 100%;
        margin-top: 10px;
    }

    #uploadButton {
        margin: 0 auto;
        width: 100%;
        margin-top: 10px;
    }

    #deleteButton {
        margin: 0 auto;
        margin-top: 5px;
        width: 100%;
    }

    #downloadButton {
        margin: 0 auto;
        margin-top: 5px;
        width: 100%;
    }

    #notifyCustomerButton {
        margin: 0 auto;
        width: 100%;
        margin-top: 10px;
    }

    #displayFilesHeading {
        height: 100%;
    }

    #displayFilesWrapper {
        height: 100%;
    }

    #displayFileTileMessage {
        margin-top: 20%;
        text-align: center;
        color: var(--textColour);
    }
}