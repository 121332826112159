#loginFormContainer {
    justify-content: center;
    position: absolute;
}

#loginHeading {
    color: var(--withe, #FFF);
    font-size: 96px;
    font-style: italic;
    font-weight: 10;
    line-height: normal;
    width: 100%;
}

#loginForm {
    padding: 2%;
    width: 75%;
    margin-top: 15%;
    background-color: var(--colour1);
    border-radius: 15px;
}
#forgotPassword {
    color: var(--textColour);
    float: right;
    cursor: pointer;
}
#loginButton {
    width: 100%;
    color: var(--textColour);
    font-weight: bold;
    background-color: var(--colour2);
    border-color: var(--colour2);
    border-radius: 0px;
}

#loginButtonWrapper {
    margin-top: 1%;
}


#seperator {
    height: 20px;
}
#signUpButton {
    color: var(--textColour);
    background-color: var(--colour5);
    border: none;
    border-radius: 0px;
    float: right;
    width: 33%;
    margin-top: 3%;
}
#noAccount {
    color: var(--textColour);
    display: inline-block;
    width: 30%;
    margin-top: 1%;
}
#oauthPrompt {
    color: var(--textColour);
    margin-top: 5%;
}

@media only screen and (max-width: 1210px) {
    #loginForm {
        display: block;
        position: relative;
        width: 100%;
    }
}
@media only screen and (max-width: 770px) {
    #loginForm {
        justify-content: center;
    } 
}