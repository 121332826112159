#container {
    width: 100%;
    min-height: 70vh;
}

#wrapper {
    width: 35%;
    margin: 0 auto;
    margin-top: 10%;

}

.heading {
    color: var(--textColour);
}

.submitButtons {
    background-color: var(--colour2);
    border: none;
    float: right;
    margin-top: 2%;
}

.backButtons {
    background-color: var(--colour3);
    border: none;
    float: right;
    margin-top: 2%;
    margin-right: 1%;
}

#resendCodeButton {
    background-color: var(--colour5);
    border: none;
    float: right;
    margin-top: 2%;
    margin-right: 1%;
}

.textField {
    width: 100%;
    margin: 0 auto
}

.alert {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 70%;
    top: 10%;
    text-align: center;
}
@media only screen and (max-width: 1210px) {
    #wrapper {
        width: 90%;
        margin: 0 auto;
        margin-top: 50%;
    
    }   
}