:root {
  --colour1: #2A2D34;
  --colour2: #F26430;
  --colour2Hover: #d1562a;
  --colour3: #009DDC;
  --colour4: #009B72;
  --colour5: #6761A8;
  --textColour: #ffffff;
  --menuColour: #212529;
  /* --colour6 is mostly borders and borders */
  --colour6: #464b5666; 
  --acceptColour: #008000;
  --acceptColourHover: #02c302;
  --declineColour: #ff0000;
  --declineColourHover: #ff3d3d;
}