#card {
    width: 85vw;
    max-width: 25rem;
    display: inline-block;
    cursor: pointer;
    border: 1px solid #605E5E;
    background: var(--colour1);
    color: var(--textColour);
    --bs-card-border-radius: none;
    min-height: 22rem;
}

.subtitleBounty {
    text-align: right;
    position: absolute;
    bottom: 5px;
    right: 5px;
    background-color: var(--colour2);
    padding: 2px;
    border-radius: 3px;
}

.tooltip {
    position: relative;
    display: inline-block;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 160px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 0%;
    margin-left: -60px;
  }
  
  .tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }

#cardTitle {
    text-align: center;
}

.cardImage {
    height: 250px;
    object-fit: cover;
}

.skillChip {
    display: none;
}

@media only screen and (max-width: 1338px) {
    .skillChip {
        padding: 5px 0 0 0;
        display: none;
    }
}