#requestPageWrapper {
    margin-top: 15px;
    min-height: 100vh;
}
#leftColumn {
    height: 80vh;
}

#displaySubmissionsWrapper {
    width: 100%;
    height: 9.5%;
    margin-bottom: 0.5%;
}

#displayFilesWrapper {
    background-color: var(--colour6);
    padding: 10px;
    display: flex;
    height: 90%;
    justify-content: center;
    align-items: center;
}

#uploadedFilesSelector {
    min-width: 100%;
}

#downloadButton {
    background-color: var(--colour2);
    color: var(--textColour);
    margin-left: 10px;
    font-size: 13px;
    width: 180px;
    display: inline;
    float: right;
}

#fileWrapper {
    max-height: 90%;
    min-width: 100%;
    margin-top: 5px;
    overflow: auto;
}

#displayFileTileMessage {
    margin-top: 100%;
    text-align: center;
    color: var(--textColour);
}

/* ====================================== */
#rightColumn {
    min-height: 80vh;
    height: 100%;
}

#contolPanelWrapper {
    background-color: var(--colour6);
    padding: 10px;
    height: 100%;
}

#requestHeading {
    color: var(--colour2);
    text-align: center;
}

.requestParagraph {
    color: var(--textColour);
    text-align: center;
}

#requestButtonWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

#requestChangesButton {
    background-color: var(--colour3);
    color: var(--textColour);
}

#releasePaymentButton {
    background-color: var(--colour4);
    color: var(--textColour);
}

#reportSubmissionButton {
    background-color: red;
    color: var(--textColour);
}
#selectedCardWriteUp {
    color: var(--textColour);
    font-size: medium;
    margin: 3%;
    background-color: var(--colour6);
    padding: 3%;
}
@media only screen and (max-width: 1210px) {
    #requestButtonWrapper {
        display: flex;
        flex-direction: column;
    }

    #requestChangesButton {
        width: 100%;
        margin-top: 5px;
    }

    #releasePaymentButton {
        width: 100%;
        margin-top: 5px;
    }

    #reportSubmissionButton {
        width: 100%;
        margin-top: 5px;
    }
}

@media only screen and (max-width: 770px) {
    #rightColumn {
        height: 100%;
    }

    #leftColumn {
        height: 50vh;
        margin-bottom: 10%;
    }

    #displayFileTileMessage {
        margin-top: 50%;
    }

    #requestButtonWrapper {
        display: flex;
        flex-direction: column;
    }

    #requestChangesButton {
        width: 100%;
        margin-top: 5px;
    }

    #releasePaymentButton {
        width: 100%;
        margin-top: 5px;
    }

    #reportSubmissionButton {
        width: 100%;
        margin-top: 5px;
    }
}